<template>
  <div class="overflow-hidden animate-pulse">
    <!-- Image -->
    <div class="rounded-t-xl w-full aspect-[13/9] mx-auto bg-[#E6E6E6] bg-opacity-90" />
    <!-- Title -->
    <div class="px-3 py-4 bg-opacity-70 bg-dark-200 sm:py-5 sm:px-4 rounded-b-xl">
      <div class="h-4 bg-[#E6E6E6] rounded-md mt-4 w-6/12" />
      <!-- Mentor -->
      <div class="h-4 bg-[#E6E6E6] rounded-md mt-2 w-8/12" />
      <!-- Icons -->
      <div class="flex mt-4 space-x-4 flex-nowrap">
        <div class="flex items-center w-1/2 space-x-2">
          <div class="w-2/12 h-3 bg-[#E6E6E6] rounded-full" />
          <div class="w-10/12 h-3 bg-[#E6E6E6] rounded-md" />
        </div>
        <div class="flex items-center w-1/2 space-x-2">
          <div class="w-2/12 h-3 bg-[#E6E6E6] rounded-full" />
          <div class="w-10/12 h-3 bg-[#E6E6E6] rounded-md" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" />
